import React, { useEffect, useState } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { string, func, bool } from 'prop-types';

import { apiBaseUrl } from '../../util/api';
import { propTypes } from '../../util/types';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { ensureListing, ensureUser } from '../../util/data';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { createResourceLocatorString } from '../../util/routes';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';

import { AvatarSmall, AvatarMedium, ResponsiveImage, AspectRatioWrapper } from '../../components';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import css from './ListingCardNew.module.css';

import { FaStar } from 'react-icons/fa6';
import { FaRegStar } from 'react-icons/fa6';
import { GrLocation } from 'react-icons/gr';
import { AiTwotoneHeart } from 'react-icons/ai';
import { FaRegStarHalfStroke } from 'react-icons/fa6';
import { ReactComponent as PhoneSVG } from '../../assets/svg/phone.svg';
import { ReactComponent as VerifiedSVG } from '../../assets/svg/verified.svg';
import { ReactComponent as WhatsAppSVG } from '../../assets/svg/whats-app.svg';
import { ReactComponent as GetDirectionSVG } from '../../assets/svg/get-direction.svg';
import { connect } from 'react-redux';
import { fetchFavListing } from '../../containers/FavouritesPage/FavouritesPage.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';

const DESC_MAX_WORDS = 80;
const MIN_LENGTH_FOR_LONG_WORDS = 10;

const renderStars = avgRating => {
  const stars = [];
  const halfStar = avgRating % 1 !== 0;
  const fullStars = Math.floor(avgRating);
  const emptyStars = 5 - Math.ceil(avgRating);

  for (let i = 0; i < fullStars; i++) {
    stars.push(<FaStar key={i} />);
  }

  if (halfStar) {
    stars.push(<FaRegStarHalfStroke key={fullStars} />);
  }

  for (let i = 0; i < emptyStars; i++) {
    stars.push(<FaRegStar key={fullStars + halfStar + i} />);
  }

  return stars;
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

export const ListingCardNewComponent = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const [isFavourite, setIsFavourite] = useState(null);
  const [favouriteLoading, setFavouriteLoading] = useState('');
  const {
    intl,
    author,
    history,
    listing,
    className,
    currentUser,
    renderSizes,
    rootClassName,
    showAuthorInfo,
    setActiveListing,
    onFetchFavListing,
    onFetchCurrentUser,
    favListingsIds,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;

  // Author details
  const ensuredAuthor = ensureUser(author);
  const authorName = ensuredAuthor.attributes.profile.displayName;
  const ensuredAuthorPublicData = ensuredAuthor?.attributes?.profile?.publicData || {};
  const isPremiumVendor = ensuredAuthorPublicData?.isPremiumVendor;
  const services = ensuredAuthorPublicData?.services || [];
  const companyName = ensuredAuthorPublicData.companyName;
  const isProvider = ensuredAuthorPublicData?.isProvider;
  const trimmedServices =
    services?.length > 2 ? services?.slice(0, 2)?.concat({ label: '...' }) : services;

  const callNumber = ensuredAuthorPublicData?.phoneNumber?.number;
  const callDialCode = ensuredAuthorPublicData?.phoneNumber?.dialCode;
  const vendorCallNumber = `${callDialCode}${callNumber}`;
  const whatsAppNumber = ensuredAuthorPublicData?.alternativePhoneNumber?.number;
  const whatsAppDialCode = ensuredAuthorPublicData?.alternativePhoneNumber?.dialCode;
  const vendorWhatsAppNumber = `${whatsAppDialCode}${whatsAppNumber}`;

  // Listing details
  const { title = '', publicData, description = '' } = currentListing.attributes;
  const slug = createSlug(title);
  const brandName = publicData?.brandName;
  const address = publicData?.locationFromUser?.selectedPlace?.address;
  const trimmedDescription =
    description?.length > DESC_MAX_WORDS
      ? description.slice(0, DESC_MAX_WORDS) + '...'
      : description;

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const toggleFavourites = async e => {
    e.stopPropagation();
    setFavouriteLoading(id);
    const userId = currentUser?.id?.uuid;
    const toggleUrl = `${apiBaseUrl()}/api/favourites/toggle/${id}`;

    if (!userId) {
      return history.push(
        createResourceLocatorString('LoginPage', routeConfiguration, { tab: 'login' })
      );
    }

    return axios
      .get(toggleUrl, { withCredentials: true })
      .then(res => {
        res.data.message === 'Favourite added' ? setIsFavourite(true) : setIsFavourite(false);
        setFavouriteLoading('');
      })
      .then(() => {
        onFetchFavListing({ config });
      })
      .then(() => {
        onFetchCurrentUser();
      })
      .catch(err => {
        setFavouriteLoading('');
      });
  };

  const authorContainer = (
    <div className={css.author}>
      <div className={css.authorAvatar}>
        <AvatarSmall
          user={ensuredAuthor}
          disableProfileLink={true}
          className={css.providerAvatarSmall}
        />
        <AvatarMedium
          user={ensuredAuthor}
          disableProfileLink={true}
          className={css.providerAvatarMedium}
        />
      </div>

      <div className={css.authorInformation}>
        {/* Business Name */}
        <div className={css.authorCompany}>
          <span>{companyName}</span>
          {isPremiumVendor && (
            <React.Fragment>
              <VerifiedSVG />
              <span className={css.authorIsVerified}>Verified</span>
            </React.Fragment>
          )}
        </div>

        {/* Provider Name */}
        {!isProvider && authorName && <div className={css.authorName}>{authorName}</div>}

        {/* Chips */}
        {services?.length ? (
          <div className={css.serviceChipContainer}>
            {/* {Array.from({ length: 12 }, () => trimmedServices).flat().map(service => ( */}
            {trimmedServices?.map(service => (
              <div key={service?.label}>{service?.label}</div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );

  const buttonsContainer = (
    <div className={css.buttonWrapper}>
      <button
        className={css.getDirection}
        onClick={e => {
          e.stopPropagation();

          if (window) {
            window.open(`https://www.google.com/maps?q=${address}`, '_blank');
          }
        }}
      >
        <GetDirectionSVG />
        <FormattedMessage id="ListingCard.getDirectionButton" />
      </button>

      <button
        disabled={!(callDialCode && callNumber)}
        className={classNames(css.call, {
          [css.callNotAvailable]: !(callDialCode && callNumber),
        })}
        onClick={e => {
          e.stopPropagation();
          window.location.href = `tel:${vendorCallNumber}`;
        }}
      >
        <PhoneSVG />
        {callDialCode && callNumber ? (
          <a href={`tel:${vendorCallNumber}`} onClick={e => e.stopPropagation()}>
            {vendorCallNumber}
          </a>
        ) : (
          <FormattedMessage id="ListingCard.callNotAvailableButton" />
        )}
      </button>

      <button
        disabled={!(whatsAppDialCode && whatsAppNumber)}
        className={classNames(css.whatsapp, {
          [css.callNotAvailable]: !(whatsAppDialCode && whatsAppNumber),
        })}
        onClick={e => {
          e.stopPropagation();
          const message = encodeURIComponent('Hello! I found your business in BizzProfiles');
          const url = `https://api.whatsapp.com/send?phone=${vendorWhatsAppNumber}&text=${message}`;
          window.open(url, '_blank', 'noopener noreferrer');
        }}
      >
        <WhatsAppSVG />
        {whatsAppDialCode && whatsAppNumber ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
            href={`https://api.whatsapp.com/send?phone=${vendorWhatsAppNumber}&text=${encodeURIComponent(
              `Hello! I found your business in BizzProfiles`
            )}`}
          >
            {'WhatsApp'}
          </a>
        ) : (
          <FormattedMessage id="ListingCard.callNotAvailableButton" />
        )}
      </button>

      <button className={css.sendEnquiry}>
        <FormattedMessage id="ListingCard.sendEnquiryButton" />
      </button>
    </div>
  );

  useEffect(() => {
    if (isFavourite === null && currentUser?.id?.uuid) {
      currentUser.favourites?.includes(id) ? setIsFavourite(true) : setIsFavourite(false);
    }
  }, [currentUser]);

  return (
    <article
      className={classes}
      onClick={e => {
        history.push(createResourceLocatorString('ListingPage', routeConfiguration, { id, slug }));
      }}
    >
      <section className={css.content}>
        <AspectRatioWrapper
          width={aspectWidth}
          height={aspectHeight}
          className={css.aspectRatioWrapper}
          {...setActivePropsMaybe}
        >
          <LazyImage
            alt={title}
            image={firstImage}
            variants={variants}
            sizes={renderSizes}
            rootClassName={css.rootForImage}
          />
        </AspectRatioWrapper>

        <div className={css.info}>
          <button
            onClick={toggleFavourites}
            className={classNames(css.favouritesSpan, {
              [css.favourited]: isFavourite,
              [css.favouriteLoading]: favouriteLoading === id,
            })}
          >
            <AiTwotoneHeart />
          </button>

          <div className={css.title}>
            {richText(title, {
              longWordClass: css.longWord,
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            })}
          </div>

          {/* <div className={css.productRating}>
            <div className={css.ratingIcons}>
              {true ? (
                renderStars(3.4)
              ) : (
                <React.Fragment>
                  <FaRegStar />
                  <FaRegStar />
                  <FaRegStar />
                  <FaRegStar />
                  <FaRegStar />
                </React.Fragment>
              )}
            </div>
            <div className={css.rating}>3.4</div>
          </div> */}

          <p className={css.descP}>
            {richText(trimmedDescription, {
              longWordClass: css.longWord,
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            })}
          </p>
          {showAuthorInfo && <div className={css.authorVisibleFrom1200}>{authorContainer}</div>}

          <div className={classNames(css.utility, css.visibleUtilitySection)}>
            {showAuthorInfo && authorContainer}
            <p className={css.locationP}>
              <GrLocation />
              <span>{address}</span>
            </p>
            <div className={css.uptoLargeScreens}>{buttonsContainer}</div>
          </div>
        </div>
      </section>

      <section className={classNames(css.utility, css.invisibleUtilitySection)}>
        {showAuthorInfo && <div className={css.authorInvisibleFrom1200}>{authorContainer}</div>}
        <p className={css.locationP}>
          <GrLocation />
          <span>{address}</span>
        </p>
        <div className={css.uptoLargeScreens}>{buttonsContainer}</div>
      </section>
    </article>
  );
};

ListingCardNewComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  showAuthorInfo: true,
  setActiveListing: null,
};

ListingCardNewComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  showAuthorInfo: bool,
  setActiveListing: func,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,
};

const mapStateToProps = state => {
  const { favListingsIds } = state.FavouritesPage;
  return { favListingsIds };
};

const mapDispatchToProps = dispatch => ({
  onFetchFavListing: props => dispatch(fetchFavListing(props)),
  onFetchCurrentUser: props => dispatch(fetchCurrentUser(props)),
});

export default compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(ListingCardNewComponent);
