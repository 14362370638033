import React, { useRef, useMemo, useState, useEffect } from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Field } from 'react-final-form';
import { format, parse } from './fiFormatter';
import { number, object, string } from 'prop-types';

import ValidationError from '../ValidationError/ValidationError';
import { phoneDialCodeData, defaultPhoneCodeCountry } from '../../marketplace-custom-config';

import css from './FieldPhoneNumberInputWithDialCode.module.css';
import { ReactComponent as ArrowUp } from '../../assets/svg/arrowUp.svg';
import { ReactComponent as ArrowDown } from '../../assets/svg/arrowDown.svg';
import { ReactComponent as DialIconSVG } from '../../assets/svg/dialIcon.svg';

const FieldPhoneNumberInputComponent = props => {
  const {
    rootClassName,
    className,
    validationErrorClassName,
    id,
    label,
    inputClassName,
    input,
    meta,
    intl,
    dispatch,
    userNativeLang,
    errorClassName,
    ...rest
  } = props;

  const searchInputRef = useRef(null);
  const [search, setSearch] = useState('');
  const [showPhoneCodes, setShowPhoneCodes] = useState(false);
  const { name, onChange, value, onBlur, onFocus } = input;
  const { number = '', iso3 } = value || {};
  const currrentSavedPhoneData = useMemo(() => {
    const savedValue = phoneDialCodeData.find(option => option.iso3 === iso3);
    if (!savedValue) {
      return defaultPhoneCodeCountry;
    } else if (!savedValue.dialCode || !savedValue.maxDigit) return defaultPhoneCodeCountry;
    return savedValue;
  }, [iso3]);

  const { dialCode = '', maxDigit } = currrentSavedPhoneData;

  const { invalid, error, touched, valid } = meta;

  const hasError = touched && invalid && error;

  if (!!label && !id) {
    throw new Error('id required when a label is given');
  }

  const filteredPhoneData = useMemo(
    () =>
      phoneDialCodeData.filter(option =>
        !!search ? option.name[userNativeLang].toLowerCase().includes(search.toLowerCase()) : true
      ),
    [search, userNativeLang]
  );

  const classes = classNames(css.root || rootClassName, className, {
    [errorClassName]: !!errorClassName && hasError,
  });
  const inputClasses = classNames(css.inputDiv, inputClassName, {
    [css.invalidInput]: hasError,
    [css.validInput]: valid,
  });

  const errorClasses = classNames(css.validationError, validationErrorClassName);

  const handleOnPhoneCodeClick = event => {
    event.preventDefault();
    event.stopPropagation();
    setShowPhoneCodes(showPhoneCodes => !showPhoneCodes);
    setSearch('');
  };

  const handleSearchChange = event => {
    event.preventDefault();
    event.stopPropagation();
    const value = event.target.value;
    setSearch(value);
  };

  const handlePhoneCodeChangeOption = option => {
    const { number = '' } = value;
    onChange({ number, ...option });
    setShowPhoneCodes(false);
    setSearch('');
  };

  const validateAndFormatNumber = value => value.replace(/[\s-]/g, '').substring(0, maxDigit);

  const handleOnChange = (event, selectedValue) => {
    event.preventDefault();
    event.stopPropagation();
    const inputValue = event.target.value;
    const newValue = validateAndFormatNumber(inputValue || '');

    if (selectedValue?.maxDigit) {
      onChange({
        ...value,
        number: newValue,
        dialCode: selectedValue?.dialCode,
        maxDigit: selectedValue?.maxDigit,
      });
    } else {
      onChange({ ...value, dialCode: '+968', maxDigit: 8, number: newValue });
    }
  };

  const handleOnBlur = event => {
    event.preventDefault();
    event.stopPropagation();
    const newValue = validateAndFormatNumber(event.target.value || '');
    onBlur({ ...value, number: newValue });
    setShowPhoneCodes(false);
    setSearch('');
  };

  const handleOnFocus = event => {
    event.preventDefault();
    event.stopPropagation();
    const newValue = validateAndFormatNumber(event.target.value || '');
    onFocus({ ...value, number: newValue });
  };

  const handlePopupBlur = event => {
    event.preventDefault();
    event.stopPropagation();
    const relatedTarget = event.relatedTarget;
    if (!relatedTarget) {
      setShowPhoneCodes(false);
      setSearch('');
    }
  };

  const handlenoOptionClick = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(() => {
    if (showPhoneCodes && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [showPhoneCodes]);

  return (
    <React.Fragment>
      <div className={classes} tabIndex="0" onBlur={handlePopupBlur}>
        {!!label && !!id ? <label htmlFor={id}>{label}</label> : null}
        <div className={inputClasses}>
          {/* {!!dialCode && !!number && (
            <button type="button" onClick={handleOnPhoneCodeClick}>
              <DialIconSVG />
              <span>{dialCode}</span>
              {showPhoneCodes ? <ArrowUp /> : <ArrowDown />}
            </button>
          )} */}
          {true && (
            <button type="button" onClick={handleOnPhoneCodeClick}>
              <DialIconSVG />
              <span>{dialCode}</span>
              {showPhoneCodes ? <ArrowUp /> : <ArrowDown />}
            </button>
          )}
          <div className={css.divider} />
          <input
            type="text"
            {...rest}
            name={name}
            id={id}
            value={number}
            onChange={e => handleOnChange(e, value)}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
          />
        </div>
        <ValidationError className={errorClasses} fieldMeta={meta} />
        {showPhoneCodes && (
          <div className={classNames(css.phoneDialCodesContainer)}>
            <input
              type="text"
              name={`${id}-phone-search`}
              id={`${id}-phone-search`}
              placeholder={intl.formatMessage({
                id: 'SignupForm.FieldPhoneNumberInputWithDialCode.searchText',
              })}
              value={search}
              onChange={handleSearchChange}
              ref={searchInputRef}
            />
            <ul className={css.phoneDialCodes}>
              {filteredPhoneData.map(data => {
                return (
                  <li
                    key={data.iso3}
                    className={classNames({ [css.selectedDialCode]: data.iso3 === iso3 })}
                  >
                    <button type="button" onClick={handlePhoneCodeChangeOption.bind(null, data)}>
                      <span>{data.name[userNativeLang]}</span>
                      <span>{data.dialCode}</span>
                    </button>
                  </li>
                );
              })}
              {filteredPhoneData.length === 0 && (
                <li className={css.noSearchOption}>
                  <button type="button" onClick={handlenoOptionClick}>
                    {intl.formatMessage({
                      id: 'SignupForm.FieldPhoneNumberInputWithDialCode.noSearchOption',
                    })}
                  </button>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

FieldPhoneNumberInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  validationErrorClassName: null,
  errorClassName: null,
  inputClassName: null,
  id: null,
  label: null,
};

FieldPhoneNumberInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  errorClassName: string,

  validationErrorClassName: string,
  inputClassName: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,
  userNativeLang: string.isRequired,
};

const mapStateToProps = state => {
  const { userNativeLang } = state.ui;
  return { userNativeLang };
};

const FieldPhoneNumberInput = compose(
  connect(mapStateToProps, null),
  injectIntl
)(FieldPhoneNumberInputComponent);

const FieldPhoneNumberInputWithDialCode = props => {
  const inputProps = {
    parse,
    ...props,
    // format,
  };
  return <Field component={FieldPhoneNumberInput} {...inputProps} />;
};

export default FieldPhoneNumberInputWithDialCode;
