import axios from 'axios';

import { apiBaseUrl } from '../../util/api';
import { parse } from '../../util/urlHelpers';
import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../util/sdkLoader';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { updatedEntities, denormalisedEntities } from '../../util/data';

import { fetchCurrentUser } from '../../ducks/user.duck';
import { fetchfavouriteListing } from '../SearchPage/SearchPage.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

const { UUID } = sdkTypes;

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
// const RESULT_PAGE_SIZE = 42;

// ================ Action types ================ //
export const FAVOURITE_LISTINGS_REQUEST = 'app/SearchPage/FAVOURITE_LISTINGS_REQUEST';
export const FAVOURITE_LISTINGS_SUCCESS = 'app/SearchPage/FAVOURITE_LISTINGS_SUCCESS';
export const FAVOURITE_LISTINGS_ERROR = 'app/SearchPage/FAVOURITE_LISTINGS_ERROR';

export const UPDATE_RESULT_IDS = 'app/SearchPage/UPDATE_RESULT_IDS';

export const FILTER_FAV_LISTING = 'app/SearchPage/FILTER_FAV_LISTING';

// ================ Reducer ================ //
const resultIds = data => data.data.map(l => l.id);
const initialState = {
  pagination: null,
  fetchFavListingInProgress: false,
  favListingsIds: [],
  fetchFavListingError: null,
  currentPageResultIds: [],
};

const favouritesPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FAVOURITE_LISTINGS_REQUEST: {
      return {
        ...state,
        fetchFavListingInProgress: true,
        favListingsIds: [],
        fetchFavListingError: null,
        currentPageResultIds: [],
      };
    }
    case FAVOURITE_LISTINGS_SUCCESS: {
      const { ListingIds, metadata } = payload;

      return {
        ...state,
        fetchFavListingInProgress: false,
        // favListingsIds: payload,
        currentPageResultIds: ListingIds,
        pagination: metadata,
      };
    }
    case UPDATE_RESULT_IDS: {
      const { ListingIds } = payload;
      return {
        ...state,
        // fetchFavListingInProgress: false,
        // favListingsIds: payload,
        currentPageResultIds: ListingIds,
        // pagination: metadata,
      };
    }
    case FAVOURITE_LISTINGS_ERROR:
      console.error(payload);
      return { ...state, fetchFavListingInProgress: false, fetchFavListingError: payload };
    case FILTER_FAV_LISTING: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default favouritesPageReducer;

// ================ Action creators ================ //

export const fetchFavListingsRequest = () => ({
  type: FAVOURITE_LISTINGS_REQUEST,
});

export const fetchFavListingsSuccess = response => ({
  type: FAVOURITE_LISTINGS_SUCCESS,
  payload: response,
});
export const updatedResultIds = response => ({
  type: UPDATE_RESULT_IDS,
  payload: response,
});

export const fetchFavListingsError = e => ({
  type: FAVOURITE_LISTINGS_ERROR,
  error: true,
  payload: e,
});
export const filterFavListing = res => ({
  type: FILTER_FAV_LISTING,
  payload: res,
});
// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)

// Throwing error for new (loadData may need that info)
export const fetchFavListing = params => async (dispatch, getState, sdk) => {
  dispatch(fetchFavListingsRequest());
  const { config, page } = params;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  // await dispatch(fetchCurrentUser());
  // const fetchedcurrentUser = getState().user.currentUser;
  // const authorId = fetchedcurrentUser?.id.uuid;
  // const url = authorId && `${apiBaseUrl()}/api/favourite/get/${authorId}?page=${page}`;

  const url = `${apiBaseUrl()}/api/favourites/get?page=${page}`;
  return axios
    .get(url, { withCredentials: true })
    .then(res => {
      const favIdarray = res?.data?.map(m => m);
      const metaData = res?.meta;
      const newIDs = favIdarray?.map(m => new UUID(m));
      // dispatch(fetchFavListingsSuccess(newIDs));
      // return newIDs;
      return { favIdarray, metaData };
    })
    .then(async res => {
      const { favIdarray, metaData } = res;
      const listingDetails = await sdk.listings.query({
        // page,
        // perPage: 2,
        ids: favIdarray,
        include: ['author', 'author.profileImage', 'images'],
        'fields.user': [
          'profile.displayName',
          'profile.abbreviatedName',
          'profile.bio',
          'profile.publicData',
        ],
        'fields.image': [
          'variants.scaled-small',
          'variants.scaled-medium',
          `variants.${variantPrefix}`,
          `variants.${variantPrefix}-2x`,
          'variants.square-small',
          'variants.square-small2x',
        ],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        'limit.images': 1,
      });

      return { listingDetails, metaData };
    })
    .then(res => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      const listingData = res?.listingDetails?.data?.data;
      const ListingIds = listingData?.map(m => new UUID(m?.id?.uuid));
      const metadata = res?.metaData;
      dispatch(addMarketplaceEntities(res?.listingDetails, sanitizeConfig));
      dispatch(fetchFavListingsSuccess({ ListingIds, metadata }));
    })
    .catch(e => {
      console.log(e);
      dispatch(fetchFavListingsError(storableError(e)));
      throw e;
    });
};

export const removeFav = params => async (dispatch, getState, sdk) => {
  const { listingId, config, page } = params;

  const favouritedIds = getState()?.FavouritePage?.currentPageResultIds;
  let filteredIds = favouritedIds?.filter(f => f?.uuid !== listingId);
  const totalPages = getState()?.FavouritePage?.pagination?.totalPages;
  const totalItems = getState()?.FavouritePage?.pagination?.totalItems;
  const lastpageitemsmaybe = totalItems % getState()?.FavouritePage?.pagination?.perPage;
  const nextPage = page === totalPages ? page : page + 1;
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;
  // await dispatch(fetchCurrentUser());
  // const fetchedcurrentUser = getState().user.currentUser;
  // const authorId = fetchedcurrentUser?.id.uuid;
  // const url =
  //   authorId &&
  //   `${apiBaseUrl()}/api/favourite/get/${authorId}?page=${nextPage}&pageLimit=1`;
  const url = `${apiBaseUrl()}/api/favourites/get?page=${nextPage}&pageLimit=1`;
  if (page === totalPages) {
    if (favouritedIds?.length > 1 || (page === 1 && favouritedIds?.length === 1))
      return dispatch(updatedResultIds({ ListingIds: filteredIds }));
    else {
      const prevPage = page > 1 ? page - 1 : 1;
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set('page', prevPage);
      if (typeof window !== undefined) {
        window.location.href = currentUrl.toString();
        return;
      }
    }
  }
  return axios
    .get(url, { withCredentials: true })
    .then(res => {
      const favIdarray = res?.data?.map(m => m);

      const metaData = res?.meta;
      const newIDs = favIdarray?.map(m => new UUID(m));
      // dispatch(fetchFavListingsSuccess(newIDs));
      // return newIDs;
      return { favIdarray, metaData };
    })
    .then(async res => {
      const { favIdarray, metaData } = res;
      const listingDetails = await sdk.listings.query({
        // page,
        // perPage: 2,
        ids: favIdarray,
        include: ['author', 'author.profileImage', 'images'],
        'fields.user': [
          'profile.displayName',
          'profile.abbreviatedName',
          'profile.bio',
          'profile.publicData',
        ],
        'fields.image': [
          'variants.scaled-small',
          'variants.scaled-medium',
          `variants.${variantPrefix}`,
          `variants.${variantPrefix}-2x`,
          'variants.square-small',
          'variants.square-small2x',
        ],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        'limit.images': 1,
      });

      return { listingDetails, metaData };
    })
    .then(res => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      const listingData = res?.listingDetails?.data?.data;
      const ListingIds = listingData?.map(m => new UUID(m?.id?.uuid));
      const newFilteredIds = [...filteredIds, ...ListingIds];

      const customMeta = {
        ...getState()?.FavouritePage?.pagination,
        totalItems: totalItems - 1,
        totalPages: lastpageitemsmaybe === 1 ? totalPages - 1 : totalPages,
        // page: pageNumber,
      };

      dispatch(addMarketplaceEntities(res?.listingDetails, sanitizeConfig));
      dispatch(fetchFavListingsSuccess({ ListingIds: newFilteredIds, metadata: customMeta }));
    })
    .catch(e => {
      console.log(e);
      dispatch(fetchFavListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  return dispatch(fetchFavListing({ config, page }));
};
