/* different signup route (tab) */
export const VENDOR_SIGNUP = 'vendor-signup';
export const CUSTOMER_SIGNUP = 'customer-signup';
/* different signup route */

/* different profile settings route (tab) */
export const PROFILE_DEFAULT = 'default';
export const PROFILE_REVIEWS = 'reviews';
export const PROFILE_LISTINGS = 'listings';
export const PROFILE_CERTIFICATES = 'certificates';
/* different profile settings route (tab) */

/* phone dial codes */
export const phoneDialCodeData = [
  {
    name: {
      en: 'Oman',
      fr: 'Oman',
    },
    iso2: 'OM',
    iso3: 'OMN',
    dialCode: '+968',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Canada',
      fr: 'Canada',
    },
    iso2: 'CA',
    iso3: 'CAN',
    dialCode: '+1',
    maxDigit: 10,
    nddPrefix: '1',
  },
  {
    name: {
      en: 'China',
      fr: 'Chine',
    },
    iso2: 'CN',
    iso3: 'CHN',
    dialCode: '+86',
    maxDigit: 11,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'France',
      fr: 'France',
    },
    iso2: 'FR',
    iso3: 'FRA',
    dialCode: '+33',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Germany',
      fr: 'Allemagne',
    },
    iso2: 'DE',
    iso3: 'DEU',
    dialCode: '+49',
    maxDigit: 11,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'India',
      fr: 'Inde',
    },
    iso2: 'IN',
    iso3: 'IND',
    dialCode: '+91',
    maxDigit: 10,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Japan',
      fr: 'Japon',
    },
    iso2: 'JP',
    iso3: 'JPN',
    dialCode: '+81',
    maxDigit: 10,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Russia',
      fr: 'Russie',
    },
    iso2: 'RU',
    iso3: 'RUS',
    dialCode: '+7',
    maxDigit: 10,
    nddPrefix: '8',
  },
  {
    name: {
      en: 'United Kingdom',
      fr: 'Royaume-Uni',
    },
    iso2: 'GB',
    iso3: 'GBR',
    dialCode: '+44',
    maxDigit: 10,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'United States',
      fr: 'États-Unis',
    },
    iso2: 'US',
    iso3: 'USA',
    dialCode: '+1',
    maxDigit: 10,
    nddPrefix: '1',
  },
];

export const defaultPhoneCodeCountry = {
  name: 'Oman',
  iso2: 'OM',
  iso3: 'OMN',
  dialCode: '+968',
  maxDigit: 8,
  nddPrefix: '0',
};
/* phone dial codes */

/* options for change user's native language */
export const languageSelectOptions = {
  en: { value: 'en', label: 'EN' },
  fr: { value: 'fr', label: 'FR' },
};
/* options for change user's native language */
