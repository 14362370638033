import React from 'react';
import { AccordionItem as Item } from '@szhsin/react-accordion';

import css from './AccordionItem.module.css';

const ChevronDown = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="icon icon-tabler icon-tabler-chevron-down"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke-width="2"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <polyline points="6 9 12 15 18 9" />
  </svg>
);

/**
 * @author Supratim Majumder
 * @type {React.ExoticComponent<import('@szhsin/react-accordion').AccordionItemProps>}
 */
const AccordionItem = props => {
  const { header, ...rest } = props;

  return (
    <Item
      {...rest}
      header={
        <article className={css.header}>
          {header}
          <span className={css.chevron}>
            <ChevronDown />
          </span>
        </article>
      }
      className={css.item}
      buttonProps={{
        className: ({ isEnter }) => `${css.itemBtn} ${isEnter && css.itemBtnExpanded}`,
      }}
      contentProps={{ className: css.itemContent }}
      panelProps={{ className: css.itemPanel }}
    />
  );
};

export default AccordionItem;
