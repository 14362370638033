import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API
const defaultLocations = [
  // Reference from ChatGPT
  {
    id: 'muscat',
    predictionPlace: {
      address: 'Muscat, Oman',
      bounds: new LatLngBounds(new LatLng(23.789209, 58.739427), new LatLng(23.379882, 58.256297)),
      origin: new LatLng(23.588, 58.3829),
    },
  },
  {
    id: 'nizwa',
    predictionPlace: {
      address: 'Nizwa, Oman',
      bounds: new LatLngBounds(new LatLng(22.963351, 57.654045), new LatLng(22.846457, 57.500243)),
      origin: new LatLng(22.9333, 57.5333),
    },
  },
  {
    id: 'salalah',
    predictionPlace: {
      address: 'Salalah, Oman',
      bounds: new LatLngBounds(new LatLng(17.080148, 54.225685), new LatLng(16.848599, 53.980392)),
      origin: new LatLng(17.02, 54.09),
    },
  },
  {
    id: 'sohar',
    predictionPlace: {
      address: 'Sohar, Oman',
      bounds: new LatLngBounds(new LatLng(24.421564, 56.849472), new LatLng(24.299081, 56.630436)),
      origin: new LatLng(24.3647, 56.7468),
    },
  },
  {
    id: 'dubai',
    predictionPlace: {
      address: 'Dubai, United Arab Emirates',
      bounds: new LatLngBounds(new LatLng(25.400104, 55.480601), new LatLng(24.944938, 55.081119)),
      origin: new LatLng(25.276987, 55.296249),
    },
  },

  // Reference from Siuhuu
  // {
  //   id: 'sydney',
  //   predictionPlace: {
  //     address: 'Sydney, Australia',
  //     bounds: new LatLngBounds(
  //       new LatLng(-33.57814094522021, 151.3430209458699),
  //       new LatLng(-34.11834699888443, 150.5209286026224)
  //     ),
  //     origin: new LatLng(-33.8688197, 151.2092955),
  //   },
  // },
  // {
  //   id: 'melbourne',
  //   predictionPlace: {
  //     address: 'Melbourne, Australia',
  //     bounds: new LatLngBounds(
  //       new LatLng(-37.5112736581378, 145.512528780816),
  //       new LatLng(-38.43385934606901, 144.5937417793496)
  //     ),
  //     origin: new LatLng(-37.8136276, 144.9630576),
  //   },
  // },

  // {
  //   id: 'auckland',
  //   predictionPlace: {
  //     address: 'Auckland, New Zealand',
  //     bounds: new LatLngBounds(
  //       new LatLng(-36.66060184426172, 174.9684260722261),
  //       new LatLng(-37.05901990116617, 174.4438160493033)
  //     ),
  //     origin: new LatLng(-36.859454, 174.5660389),
  //   },
  // },
  // {
  //   id: 'suva',
  //   predictionPlace: {
  //     address: 'Suva, Fiji',
  //     bounds: new LatLngBounds(
  //       new LatLng(-18.08364734044991, 178.5062885561634),
  //       new LatLng(-18.16359066874811, 178.4196881689107)
  //     ),
  //     origin: new LatLng(-18.1235343, 178.427969, 13),
  //   },
  // },
  // {
  //   id: 'perth',
  //   predictionPlace: {
  //     address: 'Perth, Australia',
  //     bounds: new LatLngBounds(
  //       new LatLng(-31.62448547765104, 116.2390230470838),
  //       new LatLng(-32.4556424377135, 115.6840483478314)
  //     ),
  //     origin: new LatLng(-31.9523123, 115.861309),
  //   },
  // },

  // {
  //   id: 'default-helsinki',
  //   predictionPlace: {
  //     address: 'Helsinki, Finland',
  //     bounds: new LatLngBounds(new LatLng(60.29783, 25.25448), new LatLng(59.92248, 24.78287)),
  //   },
  // },
  // {
  //   id: 'default-turku',
  //   predictionPlace: {
  //     address: 'Turku, Finland',
  //     bounds: new LatLngBounds(new LatLng(60.53045, 22.38197), new LatLng(60.33361, 22.06644)),
  //   },
  // },
  // {
  //   id: 'default-tampere',
  //   predictionPlace: {
  //     address: 'Tampere, Finland',
  //     bounds: new LatLngBounds(new LatLng(61.83657, 24.11838), new LatLng(61.42728, 23.5422)),
  //   },
  // },
  // {
  //   id: 'default-oulu',
  //   predictionPlace: {
  //     address: 'Oulu, Finland',
  //     bounds: new LatLngBounds(new LatLng(65.56434, 26.77069), new LatLng(64.8443, 24.11494)),
  //   },
  // },
  // {
  //   id: 'default-ruka',
  //   predictionPlace: {
  //     address: 'Ruka, Finland',
  //     bounds: new LatLngBounds(new LatLng(66.16997, 29.16773), new LatLng(66.16095, 29.13572)),
  //   },
  // },
];

export default defaultLocations;
