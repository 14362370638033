import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import { string, func, bool } from 'prop-types';
import { AiTwotoneHeart } from 'react-icons/ai';
import { useConfiguration } from '../../context/configurationContext';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { GrLocation } from 'react-icons/gr';
import { AspectRatioWrapper, ResponsiveImage } from '..';

import axios from 'axios';
import css from './ListingCard.module.css';
import { apiBaseUrl } from '../../util/api';
import { withRouter } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

const DESC_MAX_WORDS = 80;
const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);

  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);

  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const routes = useRouteConfiguration();
  const [isFavourite, setIsFavourite] = useState(null);
  const [favouriteLoading, setFavouriteLoading] = useState('');

  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    currentUser,
    history,
  } = props;
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;

  const classes = classNames(rootClassName || css.root, className);
  const { title = '', price, publicData, description = '' } = currentListing.attributes;
  const address = publicData?.locationFromUser?.selectedPlace?.address;
  const trimmedDescription =
    description?.length > DESC_MAX_WORDS
      ? description.slice(0, DESC_MAX_WORDS) + '...'
      : description;

  const slug = createSlug(title);
  // const author = ensureUser(listing.author);
  // const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const toggleFavourites = async e => {
    e.stopPropagation();
    setFavouriteLoading(id);
    const userId = currentUser?.id?.uuid;

    /*
    While logged in, currentUser is getting undefined
    and that's why if condition isn't working correctly
    */
    // if (!userId) {
    //   return history.push(
    //     createResourceLocatorString('AuthenticationPage', routes, { tab: 'login' })
    //   );
    // }

    const toggleUrl = `${apiBaseUrl()}/api/favourites/toggle/${id}`;

    return axios
      .get(toggleUrl, { withCredentials: true })
      .then(res => {
        res.data.message === 'Favourite added' ? setIsFavourite(true) : setIsFavourite(false);
        setFavouriteLoading('');
      })
      .catch(err => {
        setFavouriteLoading('');
      });
  };

  useEffect(() => {
    if (isFavourite === null && currentUser?.id?.uuid) {
      currentUser.favourites?.includes(id) ? setIsFavourite(true) : setIsFavourite(false);
    }
  }, [currentUser]);

  return (
    <section
      className={classes}
      onClick={e => {
        history.push(createResourceLocatorString('ListingPage', routes, { id, slug }));
      }}
    >
      <AspectRatioWrapper
        width={aspectWidth}
        height={aspectHeight}
        className={css.aspectRatioWrapper}
        {...setActivePropsMaybe}
      >
        <LazyImage
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
          rootClassName={css.rootForImage}
        />
        <button
          type="button"
          className={classNames(css.favouritesSpan, {
            [css.favourited]: isFavourite,
            [css.favouriteLoading]: favouriteLoading === id,
          })}
          onClick={toggleFavourites}
        >
          <AiTwotoneHeart />
        </button>
      </AspectRatioWrapper>
      <div className={css.info}>
        {/* <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} /> */}
        <div
          className={css.title}
          onClick={() => {
            history.push(createResourceLocatorString('ListingPage', routes, { id, slug }));
          }}
        >
          {richText(title, {
            longWordClass: css.longWord,
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
          })}
        </div>
        <p className={css.desc}>{trimmedDescription}</p>
        <p className={css.locationP}>
          <GrLocation />
          <span>{address}</span>
        </p>
        {/* <button className={css.viewNo}>
          <img src={call} />
          View Number
        </button> */}
        {/* <div className={css.mainInfo}>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
        </div> */}
      </div>
      <button className={css.getBstPrc}>Get Best Price</button>
    </section>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,
  setActiveListing: func,

  // Responsive image sizes hint
  renderSizes: string,
};

export default compose(withRouter, injectIntl)(ListingCardComponent);
